import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import ReadMore from "../ReadMore/ReadMore";
import "./style.css";
import { medicine_english, medicine_spanish } from "../../Assets/Medicine.json";
import { InputNumber } from 'primereact/inputnumber';
import IncrementDecrement from "./IncrementDecrement";
import { Tooltip } from 'primereact/tooltip';
import toolTipIcon from "../../Images/toolTipIcon.svg";
// import dollarIcon from "../../Images/dollarIcon.svg";


function QuestionOne({ pageData, lang }) {
  let history = useHistory();
  const [medicines, setMedicines] = useState([]);

  useEffect(() => {
    lang === 'english' ?
      setMedicines(medicine_english.map(m => ({
        label: m.tradeNameStr + " (" + m.drugName + ")" + " " + m.tradeNameSuffix,
        medicineDetails: m
      })))
      :
      setMedicines(medicine_spanish.map(m => ({
        label: m.tradeNameStr + " (" + m.drugName + ")" + " " + m.tradeNameSuffix,
        medicineDetails: m
      })))
  }, [lang])

  const [selectedMedicine, setSelectedMedicine] = useState(medicines[0]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [prescribedMedicine, setPrescribedMedicine] = useState(medicines.length > 0 && medicines[0].medicineDetails);
  const [properLocation, setProperLocation] = useState('');
  const [treatmentByDoctor, setTreatmentByDoctor] = useState('');
  const [treatmentAsOutPatient, setTreatementAsOutPatient] = useState('');
  const [houseHoldSize, sethouseHoldSize] = useState(1);
  const [annualIncome, setAnnualIncome] = useState(0);
  const [haveMedicarePartD, setMedicarePartD] = useState('');
  const [haveOtherCoverage, setOtherCoverage] = useState('');
  const [numberOfQuestions, setNumberOfQuestions] = useState(8);
  const [medicarePartDEligible, setMedicarePartDEligible] = useState(prescribedMedicine.medicareEligible);
  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState(false);
  const [activeYesBtn, setActiveYesBtn] = useState(false);
  const [activeNoBtn, setActiveNoBtn] = useState(false);
  const [userAnswers, setUserAnswers] = useState([
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' },
    { currentValue: '' }
  ]);

  useEffect(() => {
    setMedicarePartDEligible(prescribedMedicine.medicareEligible);
    let questionsCount = prescribedMedicine.medicareEligible ? 8 : 7;
    setNumberOfQuestions(questionsCount);
  }, [prescribedMedicine]);

  const handleAnswerOptionClick = () => {
    let nextQuestion = currentQuestion + 1;
    const answered = nextQuestion === 0 || nextQuestion === 4;
    setCurrentQuestionAnswered(answered);
    if (nextQuestion == 6 && !medicarePartDEligible) {
      nextQuestion = nextQuestion + 1;
    }
    setActiveNoBtn(false);
    setActiveYesBtn(false);

    let prescribedMedicine = userAnswers[0].currentValue !== '' ? userAnswers[0].currentValue.medicineDetails : medicines[0].medicineDetails;
    let incomeList = prescribedMedicine.incomeLimit;
    let houseHoldSize = userAnswers[4].currentValue !== '' ? userAnswers[4].currentValue : 1;
    let allowedIncome = houseHoldSize - 1 >= 0 && houseHoldSize - 1 < incomeList.length ? incomeList[houseHoldSize - 1] : incomeList[0];
    let annualIncome = userAnswers[5].currentValue !== '' ? userAnswers[5].currentValue : 0;


    if (nextQuestion == 7 && userAnswers[6].currentValue == 'y') {
      const url = calculateAndRedirectToResult();
      history.push({ pathname: url, state: { prescribedMedicine: prescribedMedicine, annualIncome: userAnswers[5].currentValue } });
    } else if (nextQuestion < pageData.questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      const url = calculateAndRedirectToResult();
      if (userAnswers[7].currentValue == 'n' && allowedIncome >= annualIncome) {
        history.push({ pathname: url, state: { prescribedMedicine: prescribedMedicine, annualIncome: userAnswers[5].currentValue, extraInfo: false } });
      } else {
        history.push({ pathname: url, state: { prescribedMedicine: prescribedMedicine, annualIncome: userAnswers[5].currentValue, extraInfo: true } });
      }
    }
  };

  const calculateAndRedirectToResult = () => {
    let prescribedMedicine = userAnswers[0].currentValue !== '' ? userAnswers[0].currentValue.medicineDetails : medicines[0].medicineDetails;
    let incomeList = prescribedMedicine.incomeLimit;
    let houseHoldSize = userAnswers[4].currentValue !== '' ? userAnswers[4].currentValue : 1;
    let allowedIncome = houseHoldSize - 1 >= 0 && houseHoldSize - 1 < incomeList.length ? incomeList[houseHoldSize - 1] : incomeList[0];
    let tradeNameStr = prescribedMedicine.tradeNameStr;
    let annualIncome = userAnswers[5].currentValue !== '' ? userAnswers[5].currentValue : 0;
    let moreThanAllowedIncome = annualIncome > allowedIncome;
    let properLocation = userAnswers[1].currentValue !== '' ? userAnswers[1].currentValue : 'n';
    let treatmentAsOutPatient = userAnswers[3].currentValue !== '' ? userAnswers[3].currentValue : 'n';
    let treatmentByDoctor = userAnswers[2].currentValue !== '' ? userAnswers[2].currentValue : 'n';
    let haveOtherCoverage = userAnswers[7].currentValue !== '' ? userAnswers[7].currentValue : 'n';
    let haveMedicarePartD = userAnswers[6].currentValue !== '' ? userAnswers[6].currentValue : 'n';
    if (properLocation === 'n' || treatmentAsOutPatient === 'n' || treatmentByDoctor === 'n') {
      return '/eligibility-assessment/not-eligible';
    }

    if (tradeNameStr === 'NULOJIX®') {
      if (moreThanAllowedIncome && haveOtherCoverage === 'y') {
        return '/eligibility-assessment/not-eligible';
      }
      return '/eligibility-assessment/eligible';
    }
    if (allowedIncome < annualIncome) {
      return '/eligibility-assessment/not-eligible';
    }
    // if (haveOtherCoverage === 'y') {
    //   return '/eligibility-assessment/not-eligible';
    // }
    // if (haveOtherCoverage === 'n' && allowedIncome >= annualIncome) {
    //   console.log('new condition satisfied');
    //   return '/eligibility-assessment/eligible';
    // }
    if (haveMedicarePartD === 'y') {
      return '/eligibility-assessment/medicare';
    }
    return '/eligibility-assessment/eligible';
  };

  const goToPreviousQuestion = () => {
    let previousQuestion = currentQuestion - 1;
    if (previousQuestion == 6 && !medicarePartDEligible) {
      previousQuestion = previousQuestion - 1;
    }
    setCurrentQuestion(previousQuestion);
    setCurrentQuestionAnswered(true);
  }

  let previousBtn = currentQuestion !== 0 ? <button className="px-3 previous-btn" onClick={goToPreviousQuestion}><span className="previous-text">{pageData.previousBtnLabel}</span></button> : '';

  const NavigationButtons = ({ questionNumber, currentAnswer, numberOfQuestions }) => {
    let activeBtn = (questionNumber === 4);
    return (
      <div className="row g-0 mt-4">
        <div className="me-3 continue-previous-container col-sm-12 mx-auto">
          {previousBtn}
          <button className={currentAnswer.currentValue !== ''/*currentQuestionAnswered*/ || activeBtn ? "px-3 continue-btn active" : "px-3 continue-btn"} onClick={handleAnswerOptionClick} disabled={!(currentAnswer.currentValue !== '' || activeBtn)}>
            {questionNumber === numberOfQuestions ? pageData.navigationBtn.label_final : pageData.navigationBtn.label}
          </button>
        </div>
      </div>
    );
  };

  const QuestionOptions = ({ placeholder, questionNumber, updateAnswer, currentAnswer, medicines, answerOptionLabels }) => {
    const createAnswerObject = (value) => {
      let answer = {};
      answer.currentValue = value;
      return answer;
    };
    const constructHTML = (currentQuestion, updateAnswer, currentAnswer, medicines) => {
      switch (currentQuestion) {
        case 0:
          return <div className="answer-option-one col-sm-12 mx-auto d-flex justify-content-center">
            <Dropdown
              aria-label="question"
              placeholder={placeholder}
              value={currentAnswer.currentValue !== '' ? currentAnswer.currentValue : null}
              options={medicines}
              onChange={(e) => updateAnswer(currentQuestion, createAnswerObject(e.value))}
            /></div>;
        case 1:
        case 2:
        case 3:
        case 6:
        case 7:
          return <div className="answer-option-two col-sm-12 mx-auto">
            <button onClick={() => updateAnswer(currentQuestion, createAnswerObject('y'))} className={currentAnswer.currentValue === 'y' /*stateValue === 'y' || activeYesBtn*/ ? "px-5 me-4 active" : "px-5 me-4"}><span className="option-text px-5">{answerOptionLabels.true}</span></button>
            <button onClick={() => updateAnswer(currentQuestion, createAnswerObject('n'))} className={currentAnswer.currentValue === 'n' /*stateValue === 'n' || activeNoBtn*/ ? "px-5 active" : "px-5"}><span className="option-text px-5">{answerOptionLabels.false}</span></button>
          </div>;
        case 4:
          return (<IncrementDecrement startCount={currentAnswer.currentValue !== '' ? currentAnswer.currentValue : 1} onChange={(value) => updateAnswer(currentQuestion, createAnswerObject(value))} />);
        case 5:
          return <div className="answer-option-three col-sm-12 mx-auto">
            <InputNumber
              key="inputnumber1"
              id="integeronly"
              min={0}
              value={currentAnswer.currentValue !== '' ? currentAnswer.currentValue : null}
              onChange={(e) => updateAnswer(currentQuestion, createAnswerObject(e.value))}
            />
            {/* <input type="text" value={currentAnswer.currentValue !== '' ? currentAnswer.currentValue : ''} onChange={(e) => updateAnswer(currentQuestion, createAnswerObject(e.target.value))} /> */}
          </div>;
      }
      return '';
    }

    return (
      <div className="row g-0 justify-content-center mt-5">
        {constructHTML(questionNumber, updateAnswer, currentAnswer, medicines)}
      </div>
    );
  };

  const QuestionDescription = ({ question }) => {
    if (question.description) {
      return (
        <div className="row g-0 mt-3">
          <div className="description-text col-sm-12 mx-auto">
            <p className="text-center">{question.description}</p>
          </div>
        </div>
      );
    }
    return ('');
  };

  const QuestionText = ({ question }) => {
    return (
      <div className="question-text row g-0 mt-3">
        <div className="col-lg-8 offset-lg-2 col-sm-12 text-center">
          <h2 className="me-3">
            {question.questionText}
          </h2>
          {question.hasToolTip ?
            <span className="tool-tip-div col-sm-1">
              <Tooltip target=".custom-target-icon" autoHide={false}>
                {question.questionNo === 1 ?
                  lang === 'english' ?
                    <p>Don't see your medicine? <Link to='/medicines'>These are the only medicines</Link> currently available through the Bristol Myers Squibb Patient Assistance Foundation.</p>
                    :
                    <p>¿No ve su medicamento? <Link to='/medicines'>Estos son los únicos medicamentos</Link> actualmente disponibles a través de Bristol Myers Squibb Patient Assistance Foundation.</p>
                  :
                  question.toolTip
                }
              </Tooltip>
              <img
                src={toolTipIcon}
                alt="toolTipIcon"
                className="custom-target-icon"
                data-pr-position="bottom"
                style={{ fontSize: "2rem", cursor: "pointer" }}
              ></img>
            </span> : ""}
        </div>
      </div>
    );
  };

  const QuestionNumber = ({ questionNumber, numberOfQuestions }) => {
    return (
      <div className="row g-0 mt-5">
        <div className="main-question-box col-sm-12 mx-auto">
          <p className="text-center">{pageData.question_label} {questionNumber} {questionNumber !== 1 ? "of " + numberOfQuestions : ""}</p>
        </div>
      </div>
    );
  };

  const Heading = () => {
    return (
      <div className="container">
        <div className="row g-0">
          <div className="question-one col-sm-12 mx-auto">
            <h1 className="text-center">{pageData.title}</h1>
          </div>
        </div>
      </div>
    );
  };

  const updateAnswer = (currentQuestion, answer) => {
    let newAnswers = [...userAnswers];
    newAnswers[currentQuestion] = answer;
    if (currentQuestion === 0) {
      setPrescribedMedicine(answer.currentValue.medicineDetails);
    }
    for (let i = currentQuestion + 1; i < userAnswers.length; i++) {
      let temp = { currentValue: "" };
      newAnswers[i] = temp;
    }
    setUserAnswers(newAnswers);
  };

  let questionNumber = currentQuestion + 1 >= numberOfQuestions ? numberOfQuestions : currentQuestion + 1;
  return (
    <main id="main">
      <div className="question-box">
        <Heading />
        <hr aria-hidden="true" />
        <div className="container">
          <QuestionNumber questionNumber={questionNumber} numberOfQuestions={numberOfQuestions} />
          <QuestionText question={pageData.questions[currentQuestion]} />
          <QuestionDescription question={pageData.questions[currentQuestion]} />
          {QuestionOptions({ placeholder: pageData.questions[currentQuestion].placeholder, questionNumber: currentQuestion, updateAnswer: updateAnswer, currentAnswer: userAnswers[currentQuestion], medicines: medicines, answerOptionLabels: pageData.answer_options })}
          <NavigationButtons questionNumber={currentQuestion} currentAnswer={userAnswers[currentQuestion]} numberOfQuestions={pageData.questions.length - 1} />
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
    </main>
  );











  // return (
  //   <div>
  //     <div className="question-box">
  //       <div className="container">
  //         <div className="row g-0">
  //           <div className="question-one col-sm-12 mx-auto">
  //             <h1 className="text-center">Eligibility Assessment</h1>
  //           </div>
  //         </div>
  //       </div>
  //       <hr aria-hidden="true" />
  //       <div className="container">
  //         <div className="row g-0 mt-5">
  //           <div className="main-question-box col-sm-12 mx-auto">
  //             <p className="text-center">Question {currentQuestion + 1 >= numberOfQuestions ? numberOfQuestions : currentQuestion + 1} {currentQuestion !== 0 ? "of " + numberOfQuestions : ""}</p>
  //           </div>
  //         </div>
  //         <div className="question-text row g-0 mt-3">
  //           <div className="col-lg-8 offset-lg-2 col-sm-12 text-center">
  //           <h2 className="me-3">
  //             {questions[currentQuestion].questionText}
  //           </h2>
  //           {questions[currentQuestion].hasToolTip ?
  //             <span className="tool-tip-div col-sm-1">
  //               <Tooltip target=".custom-target-icon" autoHide={false}>
  //                 {questions[currentQuestion].toolTip}
  //               </Tooltip>
  //               <img
  //                 src={toolTipIcon}
  //                 alt="toolTipIcon"
  //                 className="custom-target-icon"
  //                 data-pr-position="bottom"
  //                 style={{ fontSize: "2rem", cursor: "pointer" }}
  //               ></img>
  //             </span> : ""}
  //             </div>
  //         </div>
  //         {questions[currentQuestion].description ?
  //           <div className="row g-0 mt-3">
  //             <div className="description-text col-sm-12 mx-auto">
  //               <p className="text-center">{questions[currentQuestion].description}</p>
  //             </div>
  //           </div>
  //           : ''
  //         }
  //         <div className="row g-0 justify-content-center mt-5">
  //             {constructHTML(currentQuestion)}
  //         </div>
  //         <div className="row g-0 mt-4">
  //           <div className="me-3 continue-previous-container col-sm-12 mx-auto">
  //             {previousBtn}
  //             <button className={answers[currentQuestion].currentValue !== ''/*currentQuestionAnswered*/ || (currentQuestion === 0 || currentQuestion === 4) ? "px-3 continue-btn active" : "px-3 continue-btn"} onClick={handleAnswerOptionClick} disabled={!(currentQuestionAnswered || (currentQuestion === 0 || currentQuestion === 4))}>
  //               {currentQuestion === questions.length - 1 ? "Complete" : "Continue"}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //       <ReadMore />
  //     </div>
  //   </div>
  // );
}

export default QuestionOne;