import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import medicine from "../../Images/Medicine.svg";
import howTo from "../../Images/howTo.svg";
import eligibility from "../../Images/Eligibility.svg";
import close from "../../Images/close.png";
import "./style.css";
import ReadMore from "../ReadMore/ReadMore";
import textImage from "../../Images/text-img.svg";
import heroImage from "../../Images/homepage-banner-phone.svg";
import BMSPAF_Video_final from "../../Video/BMSPAF_Video_final.mp4";

function HomePage({ dialogNotShown, pageData, lang }) {
  const [showDialog, setShowDialog] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [dialogSeen, setDialogSeen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (dialogNotShown && !dialogSeen) {
      setShowDialog(true);
    }
  });
  let history = useHistory();
  function openLearnMore() {
    history.push("/about");
  }
  function toggle() {
    setShowVideo(true);
    var trailer = document.querySelector(".trailer");
    var video = document.querySelector("video");
    // if (video) {
    //   video.pause();
    //   video.currentTime = 0;
    // }
    var body = document.querySelector("body");
    trailer.classList.toggle("active");
    body.classList.toggle("active");
    video.pause();
    video.currentTime = 0;
  }

  const updateShowDialog = () => {
    setShowDialog(false);
    setDialogSeen(true);
  };

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  return (
    <main id="main">
      <div className="home-content">
        <div id="hero">
          <div className="hero container-fluid">
            <div className="row g-0">
              <div className="col text-col">
                <h1 className="title-text">
                  {pageData.banner.title}
                </h1>
                <Link to={pageData.banner.btn.link}>{pageData.banner.btn.label}</Link>
              </div>
              <div className="col linear-gradient-div"></div>
              <div className="col img-col">
                <img
                  src={heroImage}
                  alt="homepage image"
                  className="phone-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="home-read-more">
          <ReadMore content={pageData.readMore} />
        </div>
        <div className="container">
          <div className="whatis-paf row g-0">
            <div className="home-image col-sm-12 col-lg-6">
              <img
                className="home-image-pic"
                src={textImage}
                alt=""
              />
            </div>
            <div className="whatis-paf-content col-sm-12 col-lg-6">
              <div className="content">
                <h2>{pageData.whatispaf.title}</h2>
                <p>{pageData.whatispaf.body}</p>
                <Button
                  aria-label="Learn more about what is the Bristol Myers Squibb Patient Assistance Foundation"
                  label={pageData.whatispaf.btn1.label}
                  className="p-button-outlined p-button-help"
                  onClick={openLearnMore}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* {lang === "spanish" ? null :
                  <Link to={pageData.whatispaf.btn2.link} className="body-bold" onClick={toggle}>
                    {pageData.whatispaf.btn2.label}
                  </Link>
                } */}
              </div>
            </div>
            {/* <Dialog
              visible={showVideo}
              showHeader={false}
            >
            </Dialog> */}
            <div className="trailer">
              <video src={BMSPAF_Video_final} controls="true"></video>
              <button onClick={toggle}>
                <img
                  role="button"
                  className="btn-close"
                  src={close}
                  alt="close"
                />
              </button>
            </div>
          </div>
          <hr aria-hidden="true" />
          <div className="home-options-row row g-0">
            <div className="home-options col-sm-12 mx-auto">
              <ul className="row">
                {pageData.pafoptions.map((item, index) => (
                  <li key={index} className={`${item.class} col-sm-12 col-lg-4 d-flex flex-column`}>
                    <span>
                      <img className="" src={item.image && require(`../../Images/${item.image}`).default} alt="" />
                    </span>
                    <h3>{item.title}</h3>
                    <div>
                      <p>{item.body}</p>
                    </div>
                    <div className="mt-auto">
                      <Link to={item.btn.link} className="option-link body-bold">{item.btn.label}</Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div className="container"> */}
          <div className="home-back-to-top row g-0">
            <div className="col-sm-12 mx-auto">
              <Link
                tabIndex={0}
                style={{
                  marginTop: "1em",
                  color: "black",
                  "border-bottom": "2px solid #EB7100",
                  textDecoration: "none",
                }}
                onClick={(e) => backToTop(e)}
              >
                <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
              </Link>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Dialog
        showHeader={false}
        visible={showDialog}
      >
        <div className="dialog-details">
          {/* Interrupter Dialog Content */}
          {/* <div className='dialog-interrupter'> */}
          {/* <h4><u>{pageData.homeDialog.interrupter.title}</u></h4> 
            <p className='dialog-interrupter-msg' dangerouslySetInnerHTML={{ __html: pageData.homeDialog.interrupter.content }}></p> */}
          {/* <p className='dialog-interrupter-header' dangerouslySetInnerHTML={{ __html: pageData.homeDialog.interrupter.headercontent }}></p> */}
          {/* </div> */}
          {/* <hr aria-hidden="true" /> */}
          <h2 id="pr_id_2_header" className="dialog-header"><u>{pageData.homeDialog.title}</u></h2>
          <div className="dialog-close">
            <Button
              icon="pi pi-times"
              rounded
              text
              severity="secondary"
              aria-label="Close"
              className="dialog-btn-close"
              onClick={() => updateShowDialog()}
            />
          </div>
          {/* <p>
            {lang === 'english' ?
              <span class="bold-span">If you currently receive your medicine from BMSPAF and would like to reapply for assistance in 2024, please visit the <Link tabindex={0} className="dialog-link" to='/renew'>Reapply for Assistance</Link> tab on this website.</span>
              :
              <span class="bold-span">Si actualmente recibe su medicamento de BMSPAF y le gustaría volver a solicitar asistencia en 2024, visite la pestaña <Link tabindex={0} className="dialog-link" to='/renew'>Volver a solicitar asistencia</Link> en este sitio web.</span>
            }
          </p> */}
          <p>{pageData.homeDialog.content}</p>
          <div className="popup-custom-list">
            <ul className="custom-list">
              {pageData.homeDialog.list1.map(item => <li>{item}</li>)}
              {pageData.homeDialog.list2.map(item => <li>{item}</li>)}
            </ul>
          </div>
          <p className='home-dialog-note' dangerouslySetInnerHTML={{ __html: pageData.homeDialog.note }}></p>
          <p style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: pageData.homeDialog.closure }}></p>
        </div>
      </Dialog>
    </main>
  );
}

export default HomePage;
